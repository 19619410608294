import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/Screenshot 2024-07-06 193524.png'; // Adjust the path as needed
import { useAuth } from '../context/AuthContext';

const clientId = 'YOUR_GOOGLE_CLIENT_ID'; // Replace with your actual Google Client ID

function Sidebar() {
  const { isAuthenticated, login, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      sessionStorage.setItem('token', token); // Store token in sessionStorage
      login(token);
      navigate('/'); // Navigate to home after storing the token
    }
  }, [login, navigate]);

  const handleLogin = async () => {
    try {
      window.location.href = 'https://google-login-f5hpczc2dvc2dzez.eastus-01.azurewebsites.net/auth/google';
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token'); // Remove token from sessionStorage
    logout();
    navigate('/');
  };

  return (
    <div className="sidebar" style={{ backgroundColor: '#2c3e50', color: '#ffffff', height: '100vh', position: 'fixed', top: 0, left: 0, width: '250px', paddingTop: '20px' }}>
      <div className="navbar-brand">
        <Link to="/">
          <img src={logo} alt="Logo" className="img-fluid logo" />
        </Link>
      </div>
      <div className="sidebar-scroll">
        <div className="user-account">
          <div className="user_div">
            {/* <img src="path_to_user_image" className="user-photo" alt="User Profile Picture" /> */}
          </div>
          <div className="dropdown">
            <span>Welcome</span>
            <a href="#" className="dropdown-toggle user-name" data-toggle="dropdown">
              <strong>engagefusion</strong>
            </a>
            <ul className="dropdown-menu dropdown-menu-right account">
              <li><a href="#"><i className="icon-user"></i>My Profile</a></li>
              <li><a href="#"><i className="icon-envelope-open"></i>Messages</a></li>
              <li><a href="#"><i className="icon-settings"></i>Settings</a></li>
              <li className="divider"></li>
              <li><a href="#"><i className="icon-power"></i>Logout</a></li>
            </ul>
          </div>
        </div>
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <ul id="main-menu" className="metismenu">
            <li><Link to="/"><span>Dashboard</span></Link></li>
            <li><Link to="/search"><span>Search</span></Link></li>
            <li><Link to="/about"><span>About Me</span></Link></li>
            <li><Link to="/privacy"><span>Privacy Policy</span></Link></li>
            <li><Link to="/campaign"><span>Campaign</span></Link></li>
          </ul>
        </nav>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          {isAuthenticated ? (
            <button onClick={handleLogout}>Logout</button>
          ) : (
            <button onClick={handleLogin}>Login with Google</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

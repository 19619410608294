import axios from 'axios';
import React from 'react';

function CreateCampaign() {
  const createCampaign = (campaignName, description, numberOfPosts, numberOfViews, cost) => {
    axios.post('https://google-login-f5hpczc2dvc2dzez.eastus-01.azurewebsites.net/api/campaigns', {
      campaignName,
      description,
      numberOfPosts,
      numberOfViews,
      cost
    })
    .then(response => {
      console.log(response);
      // Handle the response from your backend here
    })
    .catch(error => {
      console.error("There was an error!", error);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const campaignName = document.getElementById('campaignName').value;
    const description = document.getElementById('description').value;
    const numberOfPosts = document.getElementById('numberOfPosts').value;
    const numberOfViews = document.getElementById('numberOfViews').value;
    const cost = document.getElementById('cost').value;
    createCampaign(campaignName, description, numberOfPosts, numberOfViews, cost);
  };

  return (
    <div className="content">
      <div className="container mt-4">
        <h1>Create a Campaign</h1>
        <form id="campaignForm" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="campaignName">Campaign Name:</label>
            <input type="text" id="campaignName" name="campaignName" className="form-control" required />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea id="description" name="description" className="form-control" required></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="numberOfPosts">Number of Posts:</label>
            <input type="number" id="numberOfPosts" name="numberOfPosts" className="form-control" required />
          </div>
          <div className="form-group">
            <label htmlFor="numberOfViews">Number of Views:</label>
            <input type="number" id="numberOfViews" name="numberOfViews" className="form-control" required />
          </div>
          <div className="form-group">
            <label htmlFor="cost">Cost:</label>
            <input type="number" id="cost" name="cost" className="form-control" required />
          </div>
          <button type="submit" className="btn btn-primary">Create Campaign</button>
        </form>
      </div>
    </div>
  );
}

export default CreateCampaign;

import axios from 'axios';
import React, { useEffect, useState } from 'react';

function ViewCampaigns() {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    axios.get('https://google-login-f5hpczc2dvc2dzez.eastus-01.azurewebsites.net/api/campaigns')
      .then(response => {
        // Check if the response data is an array
        if (Array.isArray(response.data)) {
          // Flatten the nested arrays and ensure each item is an object
          const flattenedCampaigns = response.data.flat().filter(item => typeof item === 'object' && item !== null);
          setCampaigns(flattenedCampaigns);
        } else {
          console.error('Expected an array but received:', response.data);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the campaigns!", error);
      });
  }, []);

  return (
    <div className="content">
      <div className="container mt-4">
        <h1>View Campaigns</h1>
        <div id="campaigns">
          {campaigns.map((campaign, index) => (
            <div key={index} className="campaign-item" style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc' }}>
              <h3>{campaign.name || campaign.campaignName}</h3>
              <p>{campaign.description}</p>
              <p>Posts: {campaign.numberOfPosts}</p>
              <p>Views: {campaign.numberOfViews}</p>
              <p>Cost: ${campaign.cost}</p>
              <h4>Influencers:</h4>
              <ul>
                {campaign.influencers && campaign.influencers.length > 0 ? (
                  campaign.influencers.map((influencer, idx) => (
                    <li key={idx}>{influencer.name} (ID: {influencer.id})</li>
                  ))
                ) : (
                  <li>No influencers associated</li>
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewCampaigns;

import React from 'react';

function Privacy() {
  return (
    <div className="content">
      <div className="container mt-4">
        <h1>Privacy Policy</h1>
        <div className="container">
          <p>Our privacy policy...</p>
          {/* Add your privacy policy content here */}
          <p><strong>Last Updated:</strong> February 02, 2024</p>
        <p><strong>Email:</strong> collab.digitaladarsh@gmail.com</p>

        <h2>Our Privacy Policy</h2>
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy rights and how the law protects You. We
          use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and
          use of information in accordance with this Privacy Policy.</p>

        <h3>Interpretation and Definitions</h3>
        <p><strong>Interpretation:</strong> The words of which the initial letter is capitalized have meanings defined
          under the following conditions. The following definitions shall have the same meaning regardless of whether they
          appear in singular or in plural.</p>

        <h3>Definitions</h3>
        <ul>
          <li><strong>You:</strong> means the individual accessing or using the Service, or the company, or other legal
            entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
          <li><strong>Affiliate:</strong> means an entity that controls, is controlled by or is under common control with
            a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing authority.</li>
          <li><strong>Account:</strong> means a unique account created for You to access our Service or parts of our
            Service.</li>
          <li><strong>Website:</strong> refers to iengagefusion, accessible from iengagefusion.in</li>
          <li><strong>Service:</strong> refers to the Website.</li>
          <li><strong>Country:</strong> refers to: India</li>
          <li><strong>Service Provider:</strong> means any natural or legal person who processes the data on behalf of the
            Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service,
            to provide the Service on behalf of the Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.</li>
          <li><strong>Third-party Social Media Service:</strong> refers to any website or any social network website
            through which a User can log in or create an account to use the Service.</li>
          <li><strong>Personal Data:</strong> is any information that relates to an identified or identifiable individual.
          </li>
          <li><strong>Cookies:</strong> are small files that are placed on Your computer, mobile device or any other
            device by a website, containing the details of Your browsing history on that website among its many uses.</li>
          <li><strong>Usage Data:</strong> refers to data collected if You opted in, either generated by the use of the
            Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
        </ul>
        <h3>Third Party Privacy Policies</h3>
        <p>Our Privacy Policy does not apply to other services or websites. Thus, we are advising you to consult the
          respective Privacy Policies of these third-party ad servers for more detailed information. It may include their
          practices and instructions about how to opt-out of certain options. You may find a complete list of these
          Privacy Policies and their links here: <a href="#">Privacy Policy Links</a>. By using this application, you are
          agreeing to be bound by the Terms of Service set out by our third-party providers.</p>
        <p>You can choose to disable cookies through your individual browser options. To know more detailed information
          about cookie management with specific web browsers, it can be found at the browsers' respective websites. What
          Are Cookies?</p>
        <p>Link to privacy policy of third-party service providers used by the app:</p>
        <ul>
          <li>YouTube Data API Terms of Service</li>
          <li>FireBase Analytics</li>
          <li>Youtube Terms of Service Provider of Our search data</li>
          <li>Google Privacy Policy</li>
          <li>FireBase Our Hosting, Auth and Storage provider</li>
          <li>Report URI</li>
        </ul>

        <h3>Tracking Technologies and Cookies</h3>
        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
          information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to
          improve and analyze Our Service.</p>
        <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You
          do not accept Cookies.</p>
        <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile
          device when You go offline, while Session Cookies are deleted as soon as You close your web browser.</p>
        <p><strong>Purpose:</strong> These Cookies allow us to remember choices You make when You use the Website, such as
          remembering your colour syle. The purpose of these Cookies is to provide You with a more personal experience and
          to avoid You having to re-enter your preferences every time You use the Website.</p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
